@if (displayChecklist) {

@if (headers) {
<div class="imageContainer">
  <div class="imageOverlay" [ngStyle]="{'background-image': 'url(' + background + ')'}">
    <div class="blueHeader" [innerHTML]="headers.title | safe: 'html'"></div>

    @if (headers.instructions) {
    <div class="subText" [innerHTML]="headers.instructions | safe: 'html'"></div>
    }

  </div>
</div>
}

@if (headers) {
<div class="headers">
  <h1 class="checkHeader" [innerHTML]="headers.welcomeText | safe: 'html'"></h1>
  <h3 class="checkSubHeader" [innerHTML]="headers.helperText | safe: 'html'"></h3>
</div>
}

@if (visibleChecklists) {
<div class="flex-container">
  <div class="progressDiv">
    <mat-progress-bar class="progressBar" mode="determinate" #progressBar [value]="progress"></mat-progress-bar>
    <div class="progressPercent">{{progress}}%</div>
  </div>
  <div class="checklists">
    @for (checklist of visibleChecklists; track $index) {
    <button mat-flat-button (click)="selectChecklist(checklist)"
      [ngClass]="{'selected': selectedChecklist.title === checklist.title}">
      <span>{{ checklist.title }}</span>
    </button>
    }
  </div>

  @if (selectedChecklist && selectedChecklist.items && visibleItems) {
  <div class="checklist">
    <h2 class="checkListTitle">{{ selectedChecklist.title }}</h2>
    @for (item of visibleItems; track $index) {
    <div class="item" matRipple>
      <h3 (click)="toggleItem(item)">{{$index+1}}. {{ item.title }}
        <span class="checkbox">
          @if (item.completed) {
          <svg xmlns="http://www.w3.org/2000/svg" class="check" width="24" height="24" viewBox="0 0 166 150.9">
            <path d="M0.3 96l62.4 54.1L165.6 0.3" />
          </svg>
          }

        </span>
      </h3>
      <div [innerHTML]="item.description | safe: 'html'" class="content"></div>
    </div>
    }
    <div class="checklist-nav-buttons">

      @if (selectedChecklist.title !== visibleChecklists[0].title) {
      <button class="btn" (click)="previousChecklist()">Previous Steps</button>
      }

      @if (selectedChecklist.title !== visibleChecklists[visibleChecklists.length -1].title) {
      <button class="btn" (click)="nextChecklist()">Next Steps</button>
      }

    </div>
  </div>
  }

</div>
}

}
