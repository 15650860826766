import { Transform } from './transformer.model';

export function StudentFactory(obj: any): Student {
  return Transform<Student>(obj, {
    cachedAt: (student: any) => student.cachedAt ? student.cachedAt * 1000 : undefined,
    studentId: '$.general.giden_student_id',
    studentCampusId: '$.general.giden_student_campus_id',
    studentFirstName: '$.general.giden_student_first_name',
    studentPreferredFirstName: '$.general.preferred_first_name',
    studentLastName: '$.general.giden_student_last_name',
    spbpers_citz_code: '$.general.spbpers_citz_code',
    alternateDashData: '$.general.alternate_dash_data',
    checklistCompletions: {
      reference: '$.checklist_completions',
      transform: (statuses: { [key: string]: 'Y' | 'N' }) => {
        const o: { [key: string]: boolean } = {};
        for (const key in statuses) {
          o[key] = statuses[key] === 'Y' ? true : false;
        }
        return o;
      }
    },
    financialAid: {
      reference: 'financialAid',
      transform: (financialAid: any) => Transform<FinancialAid>(financialAid, {
        financialAidYears: {
          reference: 'aidy',
          default: [],
          transform: (aidy: any) => Transform<FinancialAidYear[]>(aidy, [{
            id: 'faidy_aid_year',
            aidCode: 'ffafe_code',
            yearsDescription: 'ffafe_desc',
            fromYear: (aidYear: any) => Math.min(...aidYear.ffafe_desc.match(/\d{4}/g).map((n: string) => parseInt(n, 10))),
            toYear: (aidYear: any) => Math.max(...aidYear.ffafe_desc.match(/\d{4}/g).map((n: string) => parseInt(n, 10))),
            awards: (aidYear: any) => {
              const all = Transform<FinancialAidAward[]>(aidYear.fawdl, [{
                fund: 'fund',
                status: 'status',
                subsidized: (fawdl: any) => fawdl.subsidized === 'YES',
                amount: (fawdl: any) => (fawdl.total || '$0.00').trim()
              }]);
              return <FinancialAidAwards>{
                all,
                accepted: all.filter((a: any) => a.status === 'Accepted'),
                pending: all.filter((a: any) => a.status === 'Offered')
              };
            },
            requirements: (aidYear: any) => {
              const all = Transform<FinancialAidRequirement[]>(aidYear.freql, [{
                asOfDate: 'asofdate',
                instructions: 'instructions',
                long_desc: 'long_desc',
                requirement: 'requirement',
                fund: 'fund',
                status: 'status',
                statusCode: (src: any) => src.status_code.toUpperCase(),
                reqCode: (src: any) => src.req_code.toUpperCase(),
                reqUrl: 'req_url'
              }]);
              const documents: FinancialAidRequirement[] = [];
              const actions: FinancialAidRequirement[] = [];
              const AccountStatuses: { [key: string]: boolean } = {
                B: true,
                I: true,
                G: true,
                R: true
              };
              for (const req of all) {
                if (req.reqCode === 'REQDOC' && req.statusCode === 'R') {
                  documents.push(req);
                }
                if (AccountStatuses[req.statusCode]) {
                  actions.push(req);
                }
              }
              return { all, documents, actions };

            },
          }])
        }
      })
    },
    studentAccounts: {
      reference: 'studentAccounts',
      transform: (studentAccounts: any) => Transform<StudentAccounts>(studentAccounts, {
        semesterCharges: {
          reference: ['studentDetails', 'studDetails'],
          transform: (studentDetails: any) => Transform<SemesterCharge[]>(studentDetails, [{
            semesterCode: { reference: 'astud_term', default: 'No Data' },
            semesterName: { reference: 'astud_term_desc', default: 'No Data' },
            totalAmountDue: { reference: 'aabal_bal', default: NaN },
            totalAmountBilled: { reference: 'aabal_termbal', default: NaN },
            totalFinancialAid: { reference: 'afaid_amount', default: NaN },
            totalOtherResources: { reference: 'armsa_total', default: NaN }
          }])
        },
        semesterTerms: {
          reference: ['term', 'terms'],
          transform: (terms: any) => Transform<SemesterTerm[]>(terms, [{
            semesterCode: 'term_code',
            semesterName: 'term_desc',
            addDropCode: 'adrop_code',
            arCode: 'ar_code',
            aMessageCode: 'amessage',
            fMessageCode: 'fmessage',
            sponsors: 'aspmt',
            waivers: {
              reference: 'awavr',
              transform: (awavr: any) => Transform<BillWaiver[]>(awavr, [{
                date: 'activity_date',
                addedBy: 'user_id',
                desc: 'desc'
              }])
            },
          }])
        },
        displayRefundMsg: 'arefm_msg',
        refundCurrentPrefence: 'arefp_desc',
        refundLastDate: 'arefd_date',
        refundLastAmount: (src: any) => ('' === src.arefa_amount) ? 0 : parseFloat(src.arefa_amount),
        totalAmountDue: (src: any) => ('' === src.aabal_balance) ? 0 : parseFloat(src.aabal_balance)
      })
    },
    enrollmentAdvice: {
      reference: 'enrollAdvice',
      prepare: (enrollAdvice: any) => {
        for (const key in enrollAdvice) {
          if (enrollAdvice[key] === 'null' || enrollAdvice[key] === null) {
            delete enrollAdvice[key];
          }
        }
        return {};
      },
      postTransform: (enrollAdvice) => {
        enrollAdvice.termList.forEach(term => {
          term.visible = parseInt(term.termCode, 10) >= parseInt(enrollAdvice.admitTermCode, 10);
        });

        const maxTerm = enrollAdvice.isNewlyAdmitted ? enrollAdvice.admitTermCode : enrollAdvice.currentTermCode;
        const termCode = enrollAdvice.studentAdvisors
          .map(s => parseInt(s.termCode, 10))
          .filter(a => a <= parseInt(maxTerm, 10))
          .sort()
          .reverse()[0];
        enrollAdvice.studentAdvisors = enrollAdvice.studentAdvisors
          .filter((entry: StudentAdvisor) => parseInt(entry.termCode, 10) === termCode && entry.name);
        return enrollAdvice;
      },
      transform: (enrollAdvice: any, prepared: any) => Transform<EnrollmentAdvice>(enrollAdvice, {
        currentTermCode: 'eterm_code',
        currentTermDescription: 'eterm_desc',
        studentClassification: 'estcl_student_classification',
        academicStanding: 'eacst_standing',
        tuitionClassification: 'etcls_tuition_classification',
        withdrawalsTaken: 'enwdr_withdrawals_taken',
        withdrawalsRemaining: 'enwdr_withdrawals_remaining',
        admitTermCode: '$.student_type.admit_term',
        checklistAdmitTerm: '$.student_type.checklist_admit_term',
        checklistStudentType: '$.student_type.checklist_student_type',
        admitTermDesc: '$.student_type.admit_term_desc',
        studentType: '$.student_type.student_type',
        admitCode: '$.student_type.admit_code',
        messageLevel: '$.message_level',
        degreeMajors: 'emajr',
        levelCode: {
          reference: 'levelCode',
          transform: (level: string): LevelCode => {
            const levels = level.split('|');
            if (levels.length > 1) {
              if (levels.indexOf('UA') >= 0) {
                return 'UA';
              } else if (levels.indexOf('US') >= 0) {
                return 'US';
              }
            }
            return <LevelCode>levels[0];
          }
        },
        hopeOverallGPA: {
          reference: 'hopeOverallGPA',
          transform: (src: string) => parseFloat(src).toFixed(2)
        },
        gpaLevels: {
          reference: 'levl',
          transform: (levl: any) => Transform<GPALevel[]>(levl, [{
            levelCode: 'gpa_level',
            levelName: (src: any) => {
              const Lookup: { [key: string]: string } = {
                UA: 'Undergraduate Associate Semester GPA',
                GS: 'Graduate Semester GPA',
                US: 'Undergraduate Semester GPA',
                LW: 'Law Semester GPA'
              };
              return Lookup[src.gpa_level] || 'Level Code ' + src.gpa_level;
            },
            hasHopeScholarship: (src: any) => typeof src.ehgpa_gpa !== 'undefined',
            hopeScholarshipGPA: (src: any) => parseFloat(src.ehgpa_gpa || 0).toFixed(2),
            overallGPA: (src: any) => parseFloat(src.eogpa_gpa || 0).toFixed(2),
            institutionalGPA: (src: any) => parseFloat(src.eigpa_gpa || 0).toFixed(2),
            overallHoursAttempted: (src: any) => parseFloat(src.eoatt_hours || 0),
            overallHoursEarned: (src: any) => parseFloat(src.eoear_hours || 0),
            institutionalHoursAttemped: (src: any) => parseFloat(src.eiatt_hours || 0),
            institutionalHoursEarned: (src: any) => parseFloat(src.eiear_hours || 0),
            hopeScholarshipHoursAttempted: (src: any) => parseFloat(src.ehatt_hours || 0),
            hopeScholarshipHoursEarned: (src: any) => parseFloat(src.ehear_hours || 0),
          }])
        },
        studentAdvisors: {
          reference: 'enadv',
          transform: enadv => Transform(enrollAdvice.enadv, [{
            campusId: 'campusid',
            email: 'email',
            name: 'name',
            primary: (src: any) => src.pri_ind === 'Y',
            type: 'type',
            termCode: 'term',
            advisorCode: 'advisor_code',
          }])
        },
        termList: {
          reference: 'term',
          transform: (term: any) => Transform<EnrollmentTerm[]>(term, [{
            termCode: 'term_code',
            termDescription: 'term_desc',
            registered: (src: any) => src.registered === 'y',
            registrationAppointments: {
              reference: 'appointments',
              transform: (appointments: any) => Transform<RegistrationAppointment[]>(appointments, [{
                begin: 'eappt_begintime',
                end: 'eappt_endtime',
                from: 'eappt_from',
                to: 'eappt_to',
                termCode: 'eappt_verb',
                status: (src: any) => src.eihde_code === 'y'
              }])
            },
            visible: () => true
          }])
        },
        holdList: {
          reference: 'ehldl',
          transform: (ehldl: any) => Transform<Hold[]>(ehldl, [{
            from: 'from',
            to: 'to',
            type: 'hold_type',
            reason: 'reason'
          }])
        },
        isNewlyAdmitted: (src: any) => {
          if (!src.student_type || (new Date(src.student_type.admit_term_end_date + 'Z')).valueOf() < (new Date()).valueOf()) {
            return false;
          }
          return ['F', 'G', 'P', 'Q', 'T'].indexOf(src.student_type.student_type) > -1;
        },
        hasHolds: (src: any) => !!src.ehldl.length,
        grepGrel: (src: any) => src.grep_grel === 'Y' ? true : false
      })
    }
  });
}

export interface Student {
  studentId: string;
  studentCampusId: string;
  studentFirstName: string;
  studentPreferredFirstName: string;
  studentLastName: string;
  spbpers_citz_code: string;
  alternateDashData: string;
  cachedAt?: number;
  checklistCompletions?: { [key: string]: boolean };
  enrollmentAdvice: EnrollmentAdvice;
  financialAid: FinancialAid;
  studentAccounts: StudentAccounts;
}

export interface EnrollmentAdvice {
  hasHolds: boolean;
  currentTermCode: string;
  currentTermDescription: string;
  studentClassification: string;
  academicStanding: string;
  withdrawalsTaken: number;
  withdrawalsRemaining: number;
  tuitionClassification: string;
  admitCode: string;
  admitTermCode: string;
  checklistAdmitTerm: string;
  checklistStudentType: string;
  admitTermDesc: string;
  studentType: string;
  messageLevel: string;
  isNewlyAdmitted: boolean;
  studentAdvisors: StudentAdvisor[];
  termList: EnrollmentTerm[];
  holdList: Hold[];
  degreeMajors: DegreeMajor[];
  hopeOverallGPA: string;
  gpaLevels: GPALevel[];
  grepGrel: boolean;
  levelCode: LevelCode;
}
export type LevelCode = 'UA' | 'US' | 'UG' | 'GS' | 'LW';
export interface EnrollmentTerm {
  termCode: string;
  termDescription: string;
  registered: boolean;
  registrationAppointments: RegistrationAppointment[];
  visible: boolean;
}
export interface RegistrationAppointment {
  begin: string;
  end: string;
  from: string;
  to: string;
  termCode: string;
  status: boolean;
}
export interface Hold {
  from: string;
  to: string;
  type: string;
  reason: string;
}

export interface GPALevel {
  levelCode: LevelCode;
  levelName: string;
  overallGPA: string;
  institutionalGPA: string;
  hopeScholarshipGPA: string;
  overallHoursAttempted: number;
  overallHoursEarned: number;
  institutionalHoursAttemped: number;
  institutionalHoursEarned: number;
  hopeScholarshipHoursEarned: number;
  hopeScholarshipHoursAttempted: number;
  hasHopeScholarship: boolean;
}
export interface StudentAdvisor {
  campusId: string;
  email: string;
  name: string;
  primary: boolean;
  type: string;
  termCode: string;
  advisorCode: string;
}
export type DegreeMajor = {
  degree: string;
  major: string;
} | {
  concent_code: string | null;
  degree: string;
  degree_code: string;
  major: string;
  major_code: string;
  concent: string | null;
  concentration_code: string;
  term_desc: string;
  term_code: string;
};
export interface FinancialAid {
  financialAidYears: FinancialAidYear[];
}

export interface FinancialAidYear {
  id: string;
  aidCode: string;
  yearsDescription: string;
  fromYear: number;
  toYear: number;
  awards: FinancialAidAwards;
  requirements: FinancialAidRequirements;
}

export interface FinancialAidRequirements {
  all: FinancialAidRequirement[];
  documents: FinancialAidRequirement[];
  actions: FinancialAidRequirement[];
}
export class FinancialAidRequirement {
  asOfDate: string;
  status: string;
  long_desc: string;
  requirement: string;
  statusCode: string;
  reqCode: string;
  instructions?: string;
  fund?: string;
  reqUrl?: string;
}
export interface FinancialAidAwards {
  all: FinancialAidAward[];
  accepted: FinancialAidAward[];
  pending: FinancialAidAward[];
}
export interface FinancialAidAward {
  fund: string;
  status: string;
  subsidized: boolean;
  amount: string;
}

export interface StudentAccounts {
  semesterCharges: SemesterCharge[];
  semesterTerms: SemesterTerm[];
  totalAmountDue: number;
  refundCurrentPrefence: string;               // Preference for delivery of future refunds
  refundLastAmount: number | undefined;   // Amount of most recent refund
  refundLastDate: string | undefined;   // Date of most recent refund, e.g. "09/30/2011"
  displayRefundMsg: boolean;
}
export interface SemesterCharge {
  semesterCode: string;
  semesterName: string;
  totalAmountDue: number;
  totalAmountBilled: number;
  totalFinancialAid: number;
  totalOtherResources: number;
}

export interface SemesterTerm {
  semesterCode: string;
  semesterName: string;
  addDropCode: string;
  arCode: string;
  aMessageCode: string;
  fMessageCode: string;
  sponsors: SemesterSponsor[];
  waivers: BillWaiver[];
}

export interface SemesterSponsor {
  desc: string;
  name: string;
}
export interface BillWaiver {
  date: string;
  addedBy: string;
  desc: string;
}
