import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { AuthService } from "../services/auth.service";
import { BehaviorSubject, catchError, lastValueFrom, Observable, throwError } from "rxjs";
import { EnvService } from "./env.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private apiFailStatus;
  public apiFailStatus$;
  public static useCache: boolean = false;
  private static CACHE_QUERY_PARAMETER: string = '?cache=true';

  constructor(private http: HttpClient, private authService: AuthService) {
    this.apiFailStatus = new BehaviorSubject<{ apistatus: boolean, error: any }>({ apistatus: false, error: "" });
    this.apiFailStatus$ = this.apiFailStatus.asObservable();
  }

  async get<T>(url: string, headers: { [key: string]: string } = {}): Promise<T> {
    return <Promise<T>>lastValueFrom(this.http.get(url, { headers: await this.getHeaders(headers) })).catch(error => this.handleError(error));
  }

  async getAuth<T>(
    url: string,
    headers: { [key: string]: string } = {}
  ): Promise<T> {
    url = this.appendCacheForBannerAPI(url);
    return <Promise<T>>lastValueFrom(this.http.get(url, { headers: await this.getHeaders(headers, true) }))
      .catch((error) => this.handleError(error));
  }

  async put<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    return <Promise<T>>lastValueFrom(this.http.put(url, body, { headers: await this.getHeaders(headers) }))
      .catch((error) => this.handleError(error));
  }

  async putAuth<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    return <Promise<T>>lastValueFrom(this.http.put(url, body, { headers: await this.getHeaders(headers, true) }))
      .catch((error) => this.handleError(error));
  }

  async post<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    return <Promise<T>>lastValueFrom(this.http.post(url, body, { headers: await this.getHeaders(headers) }))
      .catch((error) => this.handleError(error));
  }

  async postAuth<T>(
    url: string,
    headers: { [key: string]: string } = {},
    body: any
  ): Promise<T> {
    url = this.appendCacheForBannerAPI(url);
    return <Promise<T>>lastValueFrom(this.http.post(url, body, { headers: await this.getHeaders(headers, true) }))
      .catch((error) => this.handleError(error));
  }

  async delete<T>(url: string, headers: { [key: string]: string } = {}): Promise<T> {
    return <Promise<T>>lastValueFrom(this.http.delete(url, { headers: await this.getHeaders(headers, true) }))
      .catch((error) => this.handleError(error));
  }

  async deleteAuth<T>(
    url: string,
    headers: { [key: string]: string } = {}
  ): Promise<T> {
    url = this.appendCacheForBannerAPI(url);
    return <Promise<T>>lastValueFrom(this.http.delete(url, { headers: await this.getHeaders(headers, true) }))
      .catch((error) => this.handleError(error));
  }

  static joinUrl(...paths: string[]) {
    let url = paths[0];
    for (let i = 1; i < paths.length; i++) {
      const path = paths[i];
      if (url.substring(-1) !== "/") {
        url += "/";
      }

      if (path.indexOf("/") === 0) {
        url += path.substring(1);
      } else {
        url += path;
      }
    }
    return url;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.apiFailStatus.next({ apiFailStatus: true, errorMessage: 'API Failed : ' + error.url });
    return throwError(() => new Error(error.message));
  }

  private appendCacheForBannerAPI(url: string) {
    return (url.includes(EnvService.BANNER_URL) && ApiService.useCache) ? url + ApiService.CACHE_QUERY_PARAMETER : url;
  }

  private async getHeaders(headers?: { [key: string]: string }, authenticated?: boolean) {
    const loginHeaders = authenticated ? await this.authService.getHeaders() : {};
    return {
      ...headers,
      ...loginHeaders,
      'X-GSUMeta': JSON.stringify({
        customerSolutionId: 'paws-portal',
        refererFull: window.location.href
      })
    }
  }
}