import { MatButtonModule } from '@angular/material/button';
import { PortalHeaderComponent } from "./../components/header/header.component";
import { TestEnvironmentComponent } from "./../components/test-environment/test-environment.component";
import { QuickLinksComponent } from "./../components/quick-links/quick-links.component";
import { DashboardComponent } from "./../components/dashboard/dashboard.component";
import { AdvisorComponent } from "./../components/advisor/advisor.component";
import { ChecklistComponent } from "./../components/checklist/checklist.component";
import { BannerComponent } from "./../components/banner/banner.component";
import { CrossPlatformMessagesComponent } from "./../components/cross-platform-messages/cross-platform-messages.component";
import { MatMenuModule } from "@angular/material/menu";
import { DowntimeComponent } from "./../components/downtime/downtime.component";
import { NoPersonaComponent } from "./../components/no-persona/no-persona.component";
import { LoginComponent } from "./../components/login/login.component";
import { Component, ChangeDetectorRef } from "@angular/core";
import { User } from "../services/auth.service";
import { ErrorService } from "../services/error.service";
import { AuthService } from "../services/auth.service";
import { AdminEngineService } from "../services/admin-engine.service";
import { Student, StudentFactory } from "../models/student-transform.model";
import { StudentService } from "../services/student.service";
import { LoadingService } from "../services/loading.service";
import { BannerData } from "../models/banner-data.model";
import { StudentResourceService } from "../services/student-resource.service";
import { RenderedComponentsService } from "../services/rendered-components.service";
import { MatDialog } from "@angular/material/dialog";
import { SelectRoleComponent } from "../components/select-role/select-role.component";
import { ApiService } from "src/services/api.service";
import { EnvService } from "src/services/env.service";
import { InitializeService } from "src/services/initialize.service";
import { MatIcon } from "@angular/material/icon";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { MessageParagraphsPipe } from 'src/pipes/messageParagraphs.pipe';
import { ContexturlPipe } from 'src/pipes/contexturl.pipe';
import { MatExpansionModule } from '@angular/material/expansion';
import { environment } from 'src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  standalone: true,
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  imports: [
    MatExpansionModule,
    PortalHeaderComponent,
    LoginComponent,
    NoPersonaComponent,
    DowntimeComponent,
    MessageParagraphsPipe,
    ContexturlPipe,
    MatMenuModule,
    MatButtonModule,
    MatIcon,
    CrossPlatformMessagesComponent,
    BannerComponent,
    ChecklistComponent,
    AdvisorComponent,
    DashboardComponent,
    NgxSkeletonLoaderModule,
    QuickLinksComponent,
    TestEnvironmentComponent
  ],
})
export class AppComponent {
  errorMessage: string;
  countdown: number;
  userData: any;
  displayChecklist: boolean = false;
  partialUserData: any;
  user: User;
  onlyStudent: boolean = true;
  persona: { label: string; code: string };
  advisorView = false;
  isDown = false;
  downtimeChecked = false;
  authFailed = false;
  student: Student;
  isLoading = true;
  preferredName = "";
  preferredFirstName = "";
  userName = "";
  personas = [];
  userPreferences: any;
  defaultRole: string;
  isOffset: boolean = false;
  apiFailStatus: boolean = false;
  plannedOutageStatus: boolean = false;
  downtimeConfig: any = null;
  downtimeStatus: boolean = false;
  downtimeMessage: any = '';
  menusData: any = null;
  info: any;
  adiviorAuthData: any = undefined;
  quickLinksItems: any = null;
  CACHE_QUERY_PARAMETER: string = 'cache=true';
  apiErrorMessage: string = '';
  cacheLevelMessage: string = '';
  missingPersonaStatus: boolean = false;
  unplannedOutageStaticDowntimePage : SafeResourceUrl= this.sanitizer.bypassSecurityTrustResourceUrl(environment.cdnDomain + "/static/paws/downtime.html");
  cacheMode: boolean = false;
  isSafari: boolean = false;
  loginPrompt: boolean = false;

  constructor(
    public authService: AuthService,
    private errorService: ErrorService,
    private apiService: ApiService,
    private adminEngineService: AdminEngineService,
    private studentService: StudentService,
    private studentResourceService: StudentResourceService,
    private initializeService: InitializeService,
    private renderedComponents: RenderedComponentsService,
    public loadingService: LoadingService,
    private messageParagraphPipe: MessageParagraphsPipe,
    private contextUrlPipe: ContexturlPipe,
    private ref: ChangeDetectorRef,
    private dialog: MatDialog,
    private sanitizer: DomSanitizer
  ) {
    // Debug Logging / Debug Access
    // (<any> window).debugLoginService = loginService;

    this.isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent) &&
      !navigator.userAgent.includes('Chrome') &&
      !navigator.userAgent.includes('Chromium');

    this.loginPrompt = this.isSafari || environment.loginConfig.prompt;

    (<any>window).debugStudentService = studentService;
    (<any>window).debugLoadStudent = (data: Student) => this.loadStudent(data);
    (<any>window).debugLoadBannerStudent = (data: BannerData) =>
      this.loadBannerStudent(data);
  }


  async ngOnInit() {


    this.initializeObservable();
    await this.initializeResources();
    await this.loginAndAssessDowntime();
    if (this.advisorView || !this.downtimeStatus) {
      this.displayChecklist = this.renderedComponents.getComponent("checklist");
    }
  }

  async initializeObservable() {
    this.apiService.apiFailStatus$.subscribe(errorData => {
      if (errorData.apiFailStatus) {
        this.apiErrorMessage = errorData.errorMessage;
        this.apiFailStatus = errorData.apiFailStatus;
      }
      this.ref.detectChanges();
    });

    this.loadingService.loadingChanges.subscribe(
      (isLoading) => (this.isLoading = isLoading)
    );
  }

  async loginAndAssessDowntime() {
    try {
      this.user = await this.authService.getUser() as User;
      StudentService.user = this.user;
      this.loadingService.start("Initializing");
      await this.assessDowntime();
      this.loadingService.stop("Initializing");
    } catch (e) {
      this.authFailed = true;
      await this.checkOffset();
    }
  }


  async initializeResources() {
    try {
      await this.messageParagraphPipe.initialize();
      this.studentResourceService.initialize();
      this.renderedComponents.initialize();
      this.contextUrlPipe.initialize();
      this.initializeService.initialize();
    } catch (e) {
      this.errorMessage = "The application encountered a fatal error while trying to load. Please manually reload the browser page or it will attempt to reload in ";
      this.countdown = 25;
      setInterval(() => this.countdown--, 1000);
      setTimeout(() => window.location.reload(), 25000);
    }

  }

  async setDefaultRole(persona) {
    this.defaultRole = persona.label;
    this.apiService.postAuth(
      EnvService.GSU_DATA_SERVICE_URL + "/user_preferences",
      { "Content-Type": "application/json" },
      {
        pantherId: this.user.profile.gsupersonpantherid,
        defaultRole: persona.label,
      }
    );
  }

  async checkOffset() {
    const offset = await this.apiService.get<{ timestampMS: number }>(EnvService.UTC_URL);
    const offsetSeconds = Math.abs((offset.timestampMS - Date.now()) / 1000);
    this.isOffset = offsetSeconds >= 290;
    console.log('offset ------------------');
    console.log(this.isOffset);
  }

  async assessDowntime() {
    this.downtimeConfig = await this.adminEngineService.getKey(EnvService.DASHBOARD_APP_ID, "downtime");
    const downtimeMode: string = this.isAdminOrTestUser ? "enabled" : this.downtimeConfig.mode.toLowerCase();
    switch (downtimeMode) {
      case "enabled":
        await this.setModeDefault();
        break;
      case "cache on schedule outage":
        await this.setModeCacheOnScheduleOutage();
        break;
      case "no cache on schedule outage":
        await this.setModeNoCacheOnScheduleOutage();
        break;
      case "cache only":
        await this.setModeCacheOnly();
        break;
      default:
        await this.setModeFullDown();
    }
  }

  get isAdminOrTestUser(): boolean {
    if (this.downtimeConfig.allow?.includes(this.authService.userSnapshot.campusId) || this.downtimeConfig.allow?.includes(this.authService.userSnapshot.pantherId)) {
      return true;
    }
    return false;
  }

  async setModeFullDown() {
    this.downtimeStatus = true;
    this.downtimeMessage = this.downtimeConfig.messages;
  }

  async setModeDefault() {
    await this.fetchAndSelectPersonas();
  }

  async setModeCacheOnScheduleOutage() {
    this.cacheMode = await this.getPlannedOutageStatus(this.downtimeConfig);
    ApiService.useCache = this.cacheMode;
    await this.fetchAndSelectPersonas();
  }
  async setModeNoCacheOnScheduleOutage() {
    this.downtimeStatus = await this.getPlannedOutageStatus(this.downtimeConfig);
    if (this.downtimeStatus) {
      this.downtimeMessage = MessageParagraphsPipe.data.NO_CACHE_ON_SCHEDULE_OUTAGE_DOWNTIME_MESSAGE;
      return;
    }
    await this.fetchAndSelectPersonas();
  }

  async setModeCacheOnly() {
    this.cacheMode = true;
    ApiService.useCache = true;
    await this.fetchAndSelectPersonas();
  }

  async fetchAndSelectPersonas() {
    return this.authService.User.then(async (user: User) => {
      await this.fetchPersonas();
      this.selectPersona();
    }).catch((err) =>{
      // this.errorService.logError(err);
      console.log(err);
    });
  }

  async fetchPersonas(): Promise<void> {
    const personasResponse = await this.apiService.getAuth(EnvService.BANNER_URL + '/personas', {});
    if (personasResponse["roles"].length == 0) {
      this.missingPersonaStatus = true;
      return;
    }
    else if (this.cacheMode) {
      this.setCacheMessageBasedOnCachedAt(personasResponse["cachedAt"]);
    }
    this.userPreferences = await this.apiService.getAuth(EnvService.GSU_DATA_SERVICE_URL + "/user_preferences", {});
    this.setPersonasMetaData(personasResponse);
    this.validatePersonaBelongsToStudent();
  }

  setCacheMessageBasedOnCachedAt(cachedAt: number) {
    const currentTime = Math.floor(Date.now() / 1000);
    const daysAgo = Math.floor((currentTime - cachedAt) / (60 * 60 * 24));
    this.cacheLevelMessage = daysAgo < 1 ? "less than one day old" :
      daysAgo < 2 ? " less than 2 days old" : "more than 2 days old";
  }


  validatePersonaBelongsToStudent() {
    if (this.personas?.length < 2 &&
      this.personas.find((i) => i.label.trim() === "Student")) {
      this.onlyStudent = true;
      this.setPersona(this.personas[0]);
    }
    else {
      this.onlyStudent = false;
    }
  }

  setPersonasMetaData(personasResponse: any) {
    const r = JSON.parse(JSON.stringify(<any>personasResponse));
    this.defaultRole = this.userPreferences.defaultRole;
    this.preferredName = r.preferredName;
    this.preferredFirstName = r.preferredFirstName;
    this.personas = r.roles;
  }

  async selectPersona() {
    if (this.userPreferences.defaultRole &&
      this.personas.find((i) => i.label === this.userPreferences.defaultRole)) {
      this.setPersona(
        this.personas.find((i) => i.label === this.userPreferences.defaultRole)
      );
    } else if (this.personas.length === 1) {
      this.setPersona(this.personas[0]);
    } else if (this.personas.length !== 0) {
      const personaDlg = this.dialog.open(SelectRoleComponent, {
        disableClose: true,
      });
      const data = {
        personas: this.personas,
        dlg: personaDlg,
        persona: undefined,
      };
      personaDlg.componentInstance["data"] = data;
      personaDlg
        .afterClosed()
        .toPromise()
        .then(() => {
          this.setDefaultRole(data.persona);
          this.setPersona(data.persona);
        });
    }
  }

  // TODO: determine logic here.
  async setPersona(persona) {

    this.student = void 0;
    this.persona = persona;
    this.advisorView = this.persona.label.includes("Advisor") ? true : false;
    this.partialUserData = { persona, ...this.user };
    this.ref.detectChanges();


    if (!this.advisorView) {
      this.loadingService.start("Loading Student Information");

      try {
        const student: Student = await this.studentService.getStudent();
        this.loadStudent(student);
      } catch (err) {
        // this.errorService.logError(err);
        console.log(err);
      }

      try {
        const targetedLists = await this.apiService.getAuth(`${EnvService.GSU_DATA_SERVICE_URL}/paws/targetedLists/${this.user.profile.gsupersonpantherid}`, {});
        this.userData = {
          persona,
          ...this.student,
          ...this.user,
          ...this.userPreferences,
          targetedLists,
        }
      } catch (err) {
        // console.log(err);
      }

      this.loadingService.stop("Loading Student Information");
    }
  }

  loadStudent(student: Student) {
    this.student = student;
    this.userName = `${this.student.studentPreferredFirstName} ${this.student.studentLastName}`;
    this.ref.detectChanges();
  }

  loadBannerStudent(student: BannerData) {
    this.student = StudentFactory(student);
    this.userName = `${this.student.studentPreferredFirstName} ${this.student.studentLastName}`;
  }

  async getPlannedOutageStatus(downtimeConfig: any): Promise<boolean> {
    try {
      const systemStatus = await this.adminEngineService.getKey("system", "status");
      const currUser = this.user['profile'].user_name;
      return this.isNotTestUser(systemStatus["users"], currUser) &&
        this.isValidOutageSchedule(systemStatus, downtimeConfig["requires"]);
    } catch (error) {
      console.log("Error fetching data from the ConfigDB");
    }
    return true;
  }

  isValidOutageSchedule(systemStatus: any, requires: any): boolean {
    const now = Date.now();
    const scheduled = systemStatus.scheduled
      .sort((a, b) => +(new Date(a.start)) - +(new Date(b.start)))
      .filter((v) => +(new Date(v.start)) <= now && now <= +(new Date(v.end)))
      .map((v) => v.services);
    const status = Object.assign({}, systemStatus.services, ...scheduled);

    return requires.some(key => key in status && status[key] === false);
  }

  isNotTestUser(users: string[], user: string): boolean {
    return !users.includes(user);
  }

}
